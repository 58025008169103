import { LegacyCard, Text } from "@shopify/polaris";
import { Box, MediaMinWidths } from "@smartrr/shared/components/primitives";
import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

import { StandardButton } from "../StandardButton";

const TableHeaderContainer = styled.div`
  margin-bottom: 0.4rem;
  & h2.Polaris-Text--headingMd {
    color: var(--p-color-text);
    font-size: 20px;
  }
`;

const StyledBox = styled(Box)`
  ${props => props.vertical && "width: 100%;"}
  > * {
    ${props =>
      props.vertical &&
      `
      width: 100%;
      text-align: center;
    `}
  }

  button {
    padding: 7px 12px;
  }
`;
const UpperBox = styled(Box)`
  border-bottom: var(--p-border-width-1) solid var(--p-color-border-subdued);
  padding: 20px 0px;
`;
const LowerBox = styled(Box)`
  margin: 20px 0px;
`;

const Header = styled.div`
  div[direction] {
    width: 100%;
  }

  .Polaris-LegacyCard {
    box-shadow: none;
    border: 1px solid #c6cdd4;
  }

  .Polaris-Icon__Svg {
    fill: #5c5f62;
  }

  .Polaris-Button {
    border-radius: 4px;
  }
`;

const HeaderContainer = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

  > h2 {
    color: #202223;
  }

  > a {
    color: #3773d9;
  }
`;
interface TableHeaderProps {
  title?: string;
  rightItems?: React.ReactNode;

  onExport?(): void;

  exportIsLoading?: boolean;
}

// TODO: extract and rename
interface AdvancedTableHeaderProps {
  title: string;
  cardElements: React.ReactNode;
  rightElement?: React.ReactNode;

  onExport?(): void;

  exportIsLoading?: boolean;
}

export function AdvancedTableHeader({
  cardElements,
  title,
  rightElement,
  ...props
}: AdvancedTableHeaderProps): JSX.Element {
  return (
    <Header>
      <HeaderContainer>
        <Text variant="headingMd" as="h2">
          {title}
        </Text>
        {rightElement}
      </HeaderContainer>
      <TableHeader {...props} rightItems={cardElements} />
    </Header>
  );
}

export function TableHeader({ title, rightItems, onExport, exportIsLoading }: TableHeaderProps): JSX.Element {
  const isMobile = !useMediaQuery({ minWidth: MediaMinWidths.Medium });

  return (
    <TableHeaderContainer>
      <LegacyCard>
        <Box
          px={1}
          py={1}
          gap={1}
          alignItems="center"
          justifyContent="space-between"
          vertical={isMobile}
          style={{ width: "100%" }}
        >
          {!!title && (
            <Text variant="headingMd" as="h2">
              {title}
            </Text>
          )}
          <StyledBox alignItems="center" gap={1} vertical={isMobile}>
            {rightItems}
            {!!onExport && !isMobile && (
              <StandardButton
                isLoading={exportIsLoading}
                clickHandler={onExport}
                buttonText={"Export to CSV"}
                loadingText={"Exporting"}
              />
            )}
          </StyledBox>
        </Box>
      </LegacyCard>
    </TableHeaderContainer>
  );
}

interface SimpleHeaderProps {
  title?: string;
  // rightItems?: React.ReactNode;
  elements: React.ReactNode;
  onExport?(): void;
  exportIsLoading?: boolean;
}

export function SimpleHeader({ elements, title, onExport, exportIsLoading }: SimpleHeaderProps): JSX.Element {
  const isMobile = !useMediaQuery({ minWidth: MediaMinWidths.Medium });

  return (
    <TableHeaderContainer>
      <UpperBox
        // px={1}
        pb={1}
        gap={1}
        alignItems="center"
        justifyContent="space-between"
        vertical={isMobile}
        style={{ width: "100%" }}
      >
        {!!title && (
          <Text variant="headingMd" as="h2">
            {title}
          </Text>
        )}
        <StyledBox alignItems="center" gap={1} vertical={isMobile}>
          {!!onExport && !isMobile && (
            <StandardButton
              isLoading={exportIsLoading}
              clickHandler={onExport}
              buttonText={"Download Reports"} // todo: generalize
              loadingText={"Exporting"}
            />
          )}
        </StyledBox>
      </UpperBox>
      <LowerBox>{elements}</LowerBox>
    </TableHeaderContainer>
  );
}
