import { Button } from "@shopify/polaris";
import React from "react";

interface StandardButtonProps {
  buttonText: string;
  loadingText: string;
  clickHandler(): void;
  isLoading?: boolean;
}

export function StandardButton({
  buttonText,
  loadingText,
  clickHandler,
  isLoading,
}: StandardButtonProps): JSX.Element {
  return (
    <div style={{ minWidth: 125 }}>
      <Button fullWidth primary disabled={isLoading} onClick={clickHandler}>
        {isLoading ? loadingText : buttonText}
      </Button>
    </div>
  );
}
